/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import classname from 'classname';
import css from './CustomToolButton.scss';
import IconButton from '../Button/IconButton';

function isAbsoluteUrl(url) {
  return url.indexOf('http://') === 0 || url.indexOf('https://') === 0;
}

const CustomToolButton = ({ icon, size, constraints, selected, onClick }) => (
  <IconButton
    ariaLabel="Custom Tool"
    onClick={onClick}
    style={{
      width: size,
      height: size,
      borderRadius: size,
    }}
    className={classname(css.CustomToolButton, {
      [css['CustomToolButton--selected']]: selected,
    })}
    icon={
      <div style={{ ...constraints }}>
        {isAbsoluteUrl(icon)
          ? (
            <img
              src={icon}
              className={css.CustomToolButton__img}
              alt="Custom Tool"
            />
          )
          : (
            <InlineSVG
              src={icon}
              className={css.CustomToolButton__svg}
            />
          )}
      </div>
    }
  />
);

CustomToolButton.defaultProps = {
  size: 60,
  constraints: {
    width: 40,
    height: 40,
  },
};

if (process.env.NODE_ENV !== 'production') {
  CustomToolButton.propTypes = {
    icon: PropTypes.string.isRequired,
    size: PropTypes.number,
    constraints: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    selected: PropTypes.bool,
    onClick: PropTypes.func,
  };
}

export default CustomToolButton;
