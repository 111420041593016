/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import UnstyledButton from '../Button/UnstyledButton';
import ScopedStylesheet from '../ScopedStylesheet';
import css from './Icon.scss';
import useUserPreferenceColor from '../../hooks/useUserPreferenceColor';

function createIcon({
  displayName,
  src,
  activeColor = '#aaa',
  activeHoverColor = '#777',
  userPreferenceColor = '',
  defaultSize,
}) {
  function Icon({
    size,
    disabled,
    readOnly,
    active,
    light, // if icon color is in user preference, then it can have a lighter version icon
    activeColor: customActiveColor,
    activeHoverColor: customActiveHoverColor,
    hover,
    label,
    className,
    onClick,
    labelPosition,
    ...props
  }) {
    const activeSelector = `&:not(.${css['Icon--disabled']}):not(.${css['Icon--read-only']}).${css['Icon--active']}`;
    let userPreferenceActiveColor;
    let userPreferenceActiveHoverColor;
    if (userPreferenceColor) {
      const colors = useUserPreferenceColor(userPreferenceColor);
      userPreferenceActiveColor = light ? colors.light : colors.normal;
      userPreferenceActiveHoverColor = colors.dark;
    }
    return (
      <ScopedStylesheet
        {...props}
        component={UnstyledButton}
        className={classname(css.Icon, className, {
          [css['Icon--disabled']]: disabled,
          [css['Icon--read-only']]: readOnly,
          [css['Icon--active']]: active,
          [css['Icon--hover']]: hover || (String(className).indexOf('js-active') !== -1),
          [css[`Icon--${labelPosition}`]]: true,
        })}
        styles={{
          [`.${css.Label}`]: {
            fontSize: Math.round(size * 0.6),
            marginRight: labelPosition === 'vertical' ? 0 : Math.round(size * 0.2),
          },
          [`&.${css['Icon--active']} svg`]: {
            fill: userPreferenceActiveColor || customActiveColor || activeColor,
          },
          [`&.${css['Icon--active']} .${css.Label}`]: {
            color: userPreferenceActiveColor || customActiveColor || activeColor,
          },
          [`${activeSelector}:hover svg, ${activeSelector}.${css['Icon--hover']} svg`]: {
            fill: userPreferenceActiveHoverColor || customActiveHoverColor || activeHoverColor,
          },
          [`${activeSelector}:hover .${css.Label}, ${activeSelector}.${css['Icon--hover']} .${css.Label}`]: {
            color: userPreferenceActiveHoverColor || customActiveHoverColor || activeHoverColor,
          },
          svg: {
            width: size,
            height: size,
          },
        }}
        onClick={() => {
          if (onClick && !disabled && !readOnly) {
            onClick();
          }
        }}
        ariaLabel={label || 'Icon button'}
      >
        <div className={css.Icon__inner}>
          {label &&
            <span className={css.Label}>
              {label}
            </span>
          }
          <InlineSVG
            src={src}
            className={css.InlineSVG}
          />
        </div>
      </ScopedStylesheet>
    );
  }
  Icon.prototype.isPureReactComponent = true;
  Icon.defaultProps = {
    size: defaultSize || 24,
    light: false,
    labelPosition: 'horizontal',
  };
  Icon.iconProps = {
    activeColor,
    activeHoverColor,
  };
  if (process.env.NODE_ENV === 'development') {
    Icon.propTypes = {
      size: PropTypes.number,
      disabled: PropTypes.bool,
      readOnly: PropTypes.bool,
      active: PropTypes.bool,
      hover: PropTypes.bool,
      label: PropTypes.node,
      onClick: PropTypes.func,
      light: PropTypes.bool,
      labelPosition: PropTypes.oneOf(['horizontal', 'vertical']),
      ariaLabel: PropTypes.string,
    };
  }
  Icon.displayName = `Icon(${displayName || src})`;
  return Icon;
}

export default createIcon;
