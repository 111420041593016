/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import classname from 'classname';
import React from 'react';
import IconButton from '../../Button/IconButton';
import { Translation } from '../../Text';
import Tooltip from '../../Tooltip';
import css from './SetVisibility.scss';

function SetVisibility({ isPrivate, onTogglePrivate, isDisabled, disabledMessage }) {
  const getTooltipTitle = () => {
    if (disabledMessage) {
      return disabledMessage;
    }

    return isPrivate
      ? <Translation value="comments.private.visible-to-team" />
      : <Translation value="comments.private.visible-to-everyone" />;
  };

  return (
    <Tooltip
      up
      center
      title={(
        <span
          className={css.SetVisibility__tooltip}
        >
          {getTooltipTitle()}
        </span>
      )}
    >
      <IconButton
        className={classname(css.SetVisibility__icon, {
          [css['SetVisibility__icon--isDisabled']]: isDisabled,
        })}
        src={isPrivate ? 'img/icons/material/visibility_off_flipped-24px.svg' : 'img/icons/material/visibility-24px.svg'}
        onClick={() => {
          if (!isDisabled) {
            onTogglePrivate();
          }
        }}
        ariaLabel={isPrivate ? <Translation value="comments.private.visible-to-team" /> : <Translation value="comments.private.visible-to-everyone" />}
      />
    </Tooltip>
  );
}

export default SetVisibility;
