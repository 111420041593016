/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { cloneElement } from 'react';
import classname from 'classname';
import { Button } from '../../Button';
import Popover from '../../Popover';
import Translation from '../../Text/Translation';
import OptionList from '../../PopupMenu/OptionList';
import Option from '../../PopupMenu/Option';
import Arrow from '../../Icon/Arrow';
import css from './DecisionButton.scss';
import Tooltip from '../../Tooltip';

class DecisionButton extends React.Component {
  state = {
    isOpened: false,
  };

  updateState = (bool) => {
    this.setState({
      isOpened: bool,
    });
    if (this.props.onToggle) {
      this.props.onToggle();
    }
  }

  select(id) {
    if (this.props.disabled) {
      return;
    }
    if (this.props.onSelect) {
      this.props.onSelect({
        id,
        message: null, // For the future...
      });
    }
  }

  render() {
    const {isOpened} = this.state;
    const { label, options, disabled, cannotLeaveDecision } = this.props;

    return (
      <Popover
        content={popover => (
          <div className={css.DecisionButton}>
            <OptionList wrap={false}>
              {options.map(option => (
                <Option
                  key={option.id}
                  label={
                    <div style={{paddingRight: 5}}>
                      {<Translation value={option.label} />}
                    </div>
                  }
                  checked={!!option.icon}
                  checkedIcon={
                    <span className={css.DecisionButton__icon}>
                      {cloneElement(option.icon, {
                        active: true,
                        readOnly: true,
                      })}
                    </span>
                  }
                  disabled={option.disabled}
                  checkedIconWidth={22}
                  onClick={() => {
                    if (option.disabled) {
                      return;
                    }
                    this.select(option.id);
                    popover.hide();
                  }}
                />
              ))}
            </OptionList>
          </div>
        )}
        maxWidth={false}
        backdrop={false}
        arrow
        up
        center
        offset={16}
        disabled={disabled}
        onShow={() => this.updateState(true)}
        onHide={() => this.updateState(false)}
      >
        <div>
          <Tooltip
            up
            center
            maxWidth={200}
            title={<Translation value="decision.warning.unlisted-reviewer" />}
            disabled={!cannotLeaveDecision}
          >
            <div>
              <div
                className={classname(css.DecisionButtonWrapper, {
                  [css['DecisionButtonWrapper--disabled']]: disabled,
                })}
              >
                <Button
                  data-walkthrough-hook="proof-decision-button"
                  className="DecisionButton"
                  disabled={disabled}
                  variant="primary"
                  label={<Translation
                    value={label}
                    params={{
                      icon: <Arrow
                        active
                        size={30}
                        activeHoverColor="#fff"
                        className={isOpened ? 'DecisionButton__arrow-down' : 'DecisionButton__arrow-up'}
                      />,
                    }}
                  />}
                />
              </div>
            </div>
          </Tooltip>
        </div>
      </Popover>
    );
  }
}

export default DecisionButton;
